import * as React from 'react';

import Layout from '../components/layout';
import {useStaticQuery, graphql} from 'gatsby';

import parse from 'html-react-parser';

import '../styles.scss';
import Seo from '../components/seo';


const Orarend = () => {

  const pageContent = useStaticQuery(graphql`
      query Orarend {
          markdownRemark(frontmatter: {pagename: {eq: "orarend"}}) {
              frontmatter {
                  title,
                  main
              }
              html
          }
      }
  `);


  const fm = pageContent?.markdownRemark?.frontmatter;
  const html = pageContent?.markdownRemark?.html;
  const heroTitle = fm?.main;

  return (
    <Layout heroTitle={heroTitle} headerStyle={'waves'}>
      <Seo title={fm?.title}/>
      {parse(html)}
    </Layout>
  );
};

export default Orarend;
